import XyzCard from '../xyz/XyzCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import DzfgldListUtil ,{IDzfgldListDataObj} from './dzfgldListUtil';

export default defineComponent ({
    name: 'XyzList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IDzfgldListDataObj=reactive<IDzfgldListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {
                    from:'DzfgldList',
                },
                gridTitle:'单证分管领导审核',
                isShowFixCol:false,
                paramHeight:54,//tab的高度
                cardFrom:'DzfgldList',
                modelComp: XyzCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/xyzProcess/pageData')
            },
            otherParams:{
                count:0,
                activeName:'all',
                customerData:[],//客户下拉数据
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new DzfgldListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                dataObj.pageList.queryParam.queryType='all';
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData;
                }
            }
        })

        const formatPageInfo=(options:any)=>{
            return options;
        }
        //tab切换事件
        const handleChange=(tabName:string)=>{
            dataObj.pageList.queryParam.queryType=tabName;
            dataObj.pageListRef.queryHandler(true);
        }
        const gridLoaded=(res:any)=>{
            dataObj.otherParams.count=res.count;
        }
        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo,handleChange,gridLoaded
        }
    }
});