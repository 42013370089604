import BaseBean from "@/utils/BaseBean";

export interface IDzfgldListDataObj {
    utilInst:DzfgldListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class DzfgldListUtil extends BaseBean{
    public dataObj:IDzfgldListDataObj

    constructor(proxy:any,dataObj:IDzfgldListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.customerData=await this.utils.OrderProviderApi.getAllCustomer({});//查询所有客户
    }
}